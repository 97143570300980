
<template>
  <el-card class="box-card" v-if="boxed" style="margin: 20px auto auto">
    <template #header v-if="titled">
      <div
        class="card-header"
        style="
          display: flex;
          font-size: 1rem;
          justify-content: space-between;
          align-items: center;
        "
      >
        <span class="TitleWords">
          <i
            :class="_titleIcon + ' NewIcon'"
            v-show="_titleIcon"
            style="margin-right: 6px"
          ></i>
          {{ _title }}</span
        >
        <slot name="btns">

        </slot>
      </div>
    </template>
    <slot></slot>
  </el-card>
  <slot v-else></slot>
</template>

<script>
export default {
  props: {
    showMeta: {
      type: Boolean,
      default: true,
    },
    title: String,
    titleIcon: String,
    titled: {
      default: true,
      type: Boolean,
    },
    boxed: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    _titleIcon() {
      return this.titleIcon || this.$route.meta.icon;
    },
    _title() {
      return this.title || (typeof this.$route.meta.title == "function" ? this.$route.meta.title() : this.$route.meta.title)  ;
    },
  },
};
</script>
