import request from "@/utils/request";
import { ref, Ref, reactive, defineAsyncComponent, toRef } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { copyFrom, copy } from "./common";

export function useQuery(filter): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });

  function query() {
    isLoading.value = true;
    request
      .get(`/setupProject/query`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query, pager];
}

export function useQueryPermissionPlan(filter): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });

  function query() {
    isLoading.value = true;
    request
      .get(`/setupProject/QueryPermissionPlan`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query, pager];
}

export function useQueryFileSignManageActionHistory(): Array<any> {
  const result = ref([]);
  function query(id) {
    if (id) {
      return request
        .get(`/setupProject/fileSignManageLogHistory/${id}`)
        .then((res) => {
          result.value = res;
          return res;
        });
    }
  }
  return [result, query];
}
export function useQueryActionHistory(id): Array<any> {
  const result = ref([]);
  function query() {
    if (id.value) {
      request.get(`/setupProject/logHistory/${id.value}`).then((res) => {
        result.value = res;
      });
    }
  }
  return [result, query];
}

export function useQueryPermissionActionHistory(id): Array<any> {
  const result = ref([]);
  function query() {
    if (id.value) {
      request
        .get(`/setupProject/permissionLogHistory/${id.value}`)
        .then((res) => {
          result.value = res;
        });
    }
  }
  return [result, query];
}

export function useSetupProject(id: Ref<number> = null): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);

  const empty = {
    id: 0,
    code: "待编号",
    createUser: 0,
    heritageBatch_ID: null,
    isRevolution: null,
    isSafeProject: null,
    safeProjectType: [],
    projectType: null,
    cityId: null,
    countryId: null,
    address: null,
    heritage_ManageOrg: null,
    heritage_OwnerSuperior: null,
    project_Name: "",
    heritageUnitDesc: null,
    projectProtectArea: null,
    projectProtectObject: null,
    forecastAmount: null,
    forecastAmountReason: null,
    projectForecastDoThings: null,
    projectBaseThingsInfo: null,
    projectDoCondition: null,
    isInProtectPlan: null,
    inProtectPlanText: null,
    isDoArcheology: null,
    doArcheologyText: null,
    isDoResearch: null,
    doResearchText: null,
    areaDesc: null,
    techLine: null,
    showIdear: null,
    planDoPeroid: null,
    planEndTime: null,
    planOpenTime: null,
    resultReview: null,
    landCondition: null,
    landOwnType: null,
    landManager: null,
    landPropType: null,
    landPropMemo: null,
    forecastBeginAmount: null,
    forecastDoAmount: null,
    sourceLocalAmount: null,
    sourceCenterAmount: null,
    otherDesc: null,
    projectWorkContent: null,
    projectNeedReason: null,
    projectWorkPlan: null,
    forecastProvAmount: null,
    contactPerson: null,
    contactPhone: null,
    createTime: null,
    writeDate: new Date(),
    status: null,
    historyProjects: [],
    historyFunds: [],
    attachList: [],
  };

  const result = reactive(copyFrom(empty));

  function remove(idValue: number): void {
    isLoading.value = true;
    return request
      .delete(`/setupProject/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function find(idValue: number) {
    isLoading.value = true;
    const v = idValue ? idValue : id.value;
    if (v) {
      return request
        .get(`/setupProject/${v}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          // console.log(
          //   Object.assign(result, copy(empty), copy(res)),
          //   copy(empty),
          //   copy(res)
          // );
          copyFrom(result, empty, res);

          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      //result.attachList.length = 0;
      // result.attachList = [];
      copyFrom(result, empty);
      return Promise.resolve(result);
    }
  }

  function save() {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id.value) {
      ps = request
        .put(`/setupProject/${id.value}`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/setupProject`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  function review(id, reviewResult) {
    isLoading.value = true;

    return request
      .put(`/setupProject/review/${id}`, reviewResult)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function findByCode(code: string) {
    isLoading.value = true;
    if (code) {
      return request
        .get(`/setupProject/findByCode/${code}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          // console.log(
          //   Object.assign(result, copy(empty), copy(res)),
          //   copy(empty),
          //   copy(res)
          // );
          if (res) {
            copyFrom(result, empty, res);
          }
          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      return Promise.resolve(result);
    }
  }

  function permissionSave() {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;

    ps = request
      .put(`/setupProject/PermissionPlanSave/${id.value}`, result)
      .then((res) => {
        result.id = res.id;
        result.permissionStatus = res.permissionStatus;
        isLoading.value = false;
        ElMessage.success({ message: "数据已保存", type: "success" });
      })
      .catch((e) => {
        isLoading.value = false;
        throw e;
      });

    return ps;
  }

  function permissionReview(id, reviewResult) {
    isLoading.value = true;

    return request
      .put(`/setupProject/PermissionPlanReview/${id}`, reviewResult)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [
    isLoading,
    result,
    find,
    save,
    remove,
    review,
    findByCode,
    permissionSave,
    permissionReview,
  ];
}

export const useSetupProjectAction = (): Array<any> => {
  const isLoading = ref(false);
  const cancel = ref(null);
  const back = (id, reason) => {
    isLoading.value = true;
    return request({
      headers: {
        "Content-Type": "application/json",
      },
      method: "put",
      url: `/setupProject/back/${id}`,
      data: JSON.stringify(reason),
    })
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const remove = (id) => {
    return request
      .delete(`/setupProject/${id}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const saveFileSign = (id, result) => {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id) {
      ps = request
        .put(`/setupProject/updateFileSign/${id}`, result)
        .then((res) => {
          copyFrom(result, {}, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
          return res;
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/setupProject/createFileSign`, result)
        .then((res) => {
          copyFrom(result, {}, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
          return res;
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  };
  const deleteFileSign = (id) => {
    isLoading.value = true;
    return request
      .delete(`/setupProject/deleteFileSign/${id}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };
  const findFileSign = (id) => {
    isLoading.value = true;
    return request
      .get(`/setupProject/findFileSign/${id}`)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };
  const queryFileSign = (filter) => {
    isLoading.value = true;
    return request
      .get(`/setupProject/queryFileSign`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };
  const backFileSign = (id, req) => {
    isLoading.value = true;
    return request
      .put(`/setupProject/backFileSign/${id}`, req)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const findFileSignProjects = (id, filter) => {
    isLoading.value = true;
    return request
      .get(`/setupProject/findFileSignProjects/${id}`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const cancelProjectReview = (id) => {
    isLoading.value = true;

    return request
      .put(`/setupProject/cancelProjectReview/${id}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  const findSetupPlanFile = (code) => {
    isLoading.value = true;
    return request
      .get(`/setupProject/findSetupPlanFile/${code}`)
      .then((res) => {
        isLoading.value = false;
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  return [
    isLoading,
    back,
    remove,
    findFileSign,
    saveFileSign,
    queryFileSign,
    findFileSignProjects,
    backFileSign,
    deleteFileSign,
    cancelProjectReview,
    findSetupPlanFile,
  ];
};
